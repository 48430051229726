import { observable, action, computed } from 'mobx'
export const KEY = 'DkdL8OVPdpa';
class User {
    @observable role = '';
    @observable auth = false;

    constructor() {
        this.initial();
    }

    @action
    async initial() {
        try {
            const res = await localStorage.getItem(KEY);
            if (res) {
                const obj = JSON.parse(res);
                for (const key in obj) {
                    if (obj.hasOwnProperty(key)) {
                        const element = obj[key];
                        this[key] = element;
                    }
                }
                this.auth = true;
            }
        } catch (error) {
            console.log(error)
        }
    }


    @action
    async login(result) {
        try {
            // await localStorage.setItem(KEY, JSON.stringify({ role: result.role }));
            await localStorage.setItem(KEY, JSON.stringify({ role: 'rizz' }));

            // this.role = result.role;
            this.role = 'rizz';
            this.auth = true;
        } catch (error) {
            console.log(error)
        }
    }

    @computed
    get getRole() {
        return this.role
    }

    @action
    async logout() {
        try {
            await localStorage.removeItem(KEY);
            this.auth = false;
            this.role = '';
        } catch (error) {
            console.log(error)
        }
    }
}

export default new User(); 