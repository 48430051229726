import React from "react";
import ClaimScene from "../scene/Rizz/Claim/ClaimScene";
import RegisterWarranty from "../scene/Rizz/Warranty/RegisterWarranty";
import { BtnConfirm } from "../components/Button";
import { ScissorOutlined } from '@ant-design/icons';
import { image } from "./images";
import {
  ColumnStatus,
  ColumnStatus_FOCUS,
  ColumnStatus_ImportAx_Focus,
  ColumnStore_type,
  ColumnStatusReportWarranty,
} from "../components/Table";
import { Tag } from "antd";
import DetailClaimScene from "../scene/Rizz/Claim/DetailClaimScene";
import EditClaimScene from "../scene/Rizz/Claim/EditClaimScene";
import CreateClaimScene from "../scene/Rizz/Claim/CreateClaimScene";
import HistoryClaimScene from "../scene/Rizz/Claim/HistoryClaimScene";
import reportWarrantyScene from "../scene/Rizz/Warranty/reportWarrantyScene";
import { SendOutlined } from "@ant-design/icons";
import ProductsWarrantyScene from "../scene/Rizz/Management/ProductsWarrantyScene";
import MultiClaimScene from "../scene/Rizz/MultiClaim/MultiClaimScene";
import EditWarrantyScene from "../scene/Rizz/Warranty/EditWarrantyScene";

export const menu = {
  rizz: {
    defaultUrl: "/rizz/claim",
    menu: [
      {
        path: "/rizz/register-warranty",
        name: "ลงทะเบียนสินค้า",
        icon: "form",
        component: RegisterWarranty,
      },
      {
        path: "/rizz/claim",
        name: "เคลมสินค้า",
        icon: "file-search",
        component: ClaimScene,
      },
      {
        path: "/rizz/multipost",
        name: "ยิงจ่ายพัสดุ",
        icon: "qrcode",
        component: MultiClaimScene,
      },
      {
        path: "/rizz/historyClaim",
        name: "ประวัติการเคลม",
        icon: "profile",
        component: HistoryClaimScene,
      },
      {
        path: "/rizz/reportWarranty",
        name: "รายงานการลงทะเบียน",
        icon: "profile",
        component: reportWarrantyScene,
      },
      {
        path: "/rizz/productsWarranty",
        name: "สินค้ารับประกัน",
        icon: "profile",
        component: ProductsWarrantyScene,
      },
    ],
    routes: [
      {
        index: 0,
        path: "/rizz/register-warranty",
        name: "ลงทะเบียนสินค้า",
        icon: "form",
        component: RegisterWarranty,
      },
      {
        index: 1,
        path: "/rizz/claim",
        name: "เคลมสินค้า",
        icon: "file-search",
        component: ClaimScene,
      },

      {
        index: 2,
        path: "/rizz/multipost",
        name: "ยิงจ่ายพัสดุ",
        component: MultiClaimScene,
      },
      {
        path: "/rizz/claim_detail",
        name: "รายละเอียด",
        component: DetailClaimScene,
      },
      {
        path: "/rizz/warranty_detail",
        name: "รายละเอียดการรับประกัน",
        component: EditWarrantyScene,
      },
      {
        path: "/rizz/billshipping",
      },
      {
        index: 2,
        path: "/rizz/historyClaim",
        name: "ประวัติการเคลม",
        component: HistoryClaimScene,
      },
      {
        index: 3,
        path: "/rizz/reportWarranty",
        name: "รายงานการลงทะเบียน",
        component: reportWarrantyScene,
      },
      {
        path: "/rizz/claim_edit",
        name: "แก้ไขรายการเคลมสินค้่า",
        component: EditClaimScene,
      },
      {
        path: "/rizz/claim_create",
        name: "ยืนเคลม",
        component: CreateClaimScene,
      },
      {
        index: 4,
        path: "/rizz/productsWarranty",
        name: "สินค้ารับประกัน",
        component: ProductsWarrantyScene,
      },
    ],
    title: "RIZZ maximize ME moment",
  },
  // focus: {
  //   defaultUrl: "focus/claim",
  //   menu: [
  //     {
  //       path: "/focus/register-warranty",
  //       name: "ลงทะเบียนสินค้า",
  //       icon: "form",
  //       component: FocusRegisterWarrantyScene,
  //     },
  //     {
  //       path: "/focus/claim",
  //       name: "เคลมสินค้า",
  //       icon: "file-search",
  //       component: FocusClaimScene,
  //     },
  //     {
  //       path: "/focus/multipost",
  //       name: "ยิงจ่ายพัสดุ",
  //       icon: "qrcode",
  //       component: FocusMultiClaimScene,
  //     },
  //     {
  //       path: "/focus/history",
  //       name: "ประวัติการเคลม",
  //       icon: "profile",
  //       component: FocusHistoryScene,
  //     },
  //     // , {
  //     //     path: '/focus/accounting',
  //     //     name: 'รายงานค่าจัดส่งสินค้า',
  //     //     icon: 'profile',
  //     //     component: FocusReportAccountingScene
  //     // }
  //     // , {
  //     //     path: '/focus/importax',
  //     //     name: 'Import to Ax',
  //     //     icon: 'profile',
  //     //     component: ImportAxScene
  //     // }
  //     {
  //       path: "/focus/warranty",
  //       name: "รายงานการลงทะเบียนสินค้า",
  //       icon: "profile",
  //       component: FocusWarrantyScene,
  //     },
  //   ],
  //   routes: [
  //     {
  //       index: 0,
  //       path: "/focus/register-warranty",
  //       name: "ลงทะเบียนสินค้า",
  //       component: FocusRegisterWarrantyScene,
  //     },
  //     {
  //       index: 1,
  //       path: "/focus/claim",
  //       name: "เคลมสินค้าFocus",
  //       component: FocusClaimScene,
  //     },
  //     {
  //       index: 2,
  //       path: "/focus/multipost",
  //       name: "ยิงจ่ายพัสดุ",
  //       component: FocusMultiClaimScene,
  //     },
  //     {
  //       index: 3,
  //       path: "/focus/history",
  //       name: "ประวัติการเคลม",
  //       component: FocusHistoryScene,
  //     },
  //     // , {
  //     //     index: 4,
  //     //     path: '/focus/accounting',
  //     //     name: 'รายงานค่าจัดส่งสินค้า',
  //     //     component: FocusReportAccountingScene

  //     // }
  //     // , {
  //     //     index: 5,
  //     //     path: '/focus/importax',
  //     //     name: 'Import to Ax',
  //     //     component: ImportAxScene
  //     // }
  //     {
  //       index: 6,
  //       path: "/focus/warranty",
  //       name: "รายงานการลงทะเบียนสินค้า",
  //       component: FocusWarrantyScene,
  //     },
  //     {
  //       path: "/focus/billshipping",
  //     },
  //     {
  //       path: "/focus/claim_detail",
  //       name: "รายละเอียด",
  //       component: FocusDetailClaimScene,
  //     },
  //     {
  //       path: "/focus/claim_edit",
  //       name: "แก้ไขรายการเคลมสินค้่า",
  //       component: FocusEditClaimScene,
  //     },
  //     {
  //       path: "/focus/claim_create",
  //       name: "ยื่นเคลม",
  //       component: FocusCreateClaimScene,
  //     },
  //   ],
  //   title: "Focus service",
  // },
};

export const columnTableClaim = (fn = () => { }, onCreateMessage = () => { }, onClick = () => { },) => [
  {
    title: "ลำดับ",
    dataIndex: "no",
    align: "center",
    width: "5vw",
    className: "td-content",
    onCell: (record, i) => ({
      onClick: () => fn(record),
    }),
    sorter: (a, b) => a.rowNo - b.rowNo,
  },
  {
    title: "เลขที่เคลม",
    dataIndex: "claim_id",
    align: "center",
    className: "td-content",
    width: "7vw",
    onCell: (record, i) => ({
      onClick: () => fn(record),
    }),
    sorter: (a, b) =>
      a.claimNo < b.claimNo ? -1 : a.claimNo > b.claimNo ? 1 : 0,
  },
  {
    title: "ชื่อไลน์",
    dataIndex: "line_name",
    align: "center",
    className: "td-content",
    width: "8vw",
    onCell: (record, i) => ({
      onClick: () => fn(record),
    }),
  },
  {
    title: "ชื่อ-นามสกุล",
    dataIndex: "name",
    align: "center",
    className: "td-content",
    width: "9vw",
    onCell: (record, i) => ({
      onClick: () => fn(record),
    }),
  },
  {
    title: "เบอร์โทรศัพท์",
    dataIndex: "telephone",
    align: "center",
    className: "td-content",
    onCell: (record, i) => ({
      onClick: () => fn(record),
    }),
  },
  {
    title: "วันที่แจ้ง",
    dataIndex: "create_at",
    align: "center",
    width: "6vw",
    className: "td-content",
    onCell: (record, i) => ({
      onClick: () => fn(record),
    }),
    sorter: (a, b) =>
      a.create_at < b.create_at ? -1 : a.create_at > b.create_at ? 1 : 0,
  },
  {
    title: "ชื่อสินค้า",
    dataIndex: "product_name",
    align: "center",
    className: "td-content",
    width: "8vw",
    onCell: (record, i) => ({
      onClick: () => fn(record),
      style: {
        whiteSpace: "wrap",
        maxWidth: "8vw",
      },
    }),
    render: (text) => {
      return (
        <div style={{ fontSizeAdjust: 1 }}>
          <font className={"font-table"}>{text}</font>
        </div>
      );
    },
  },
  // {
  //   title: "วันคงเหลือ",
  //   dataIndex: "time_warranty",
  //   align: "center",
  //   className: "td-content",
  //   width: "6vw",
  //   onCell: (record, i) => ({
  //     onClick: () => fn(record),
  //   }),
  //   sorter: (a, b) =>
  //     a.time_warranty < b.time_warranty
  //       ? -1
  //       : a.time_warranty > b.time_warranty
  //       ? 1
  //       : 0,
  // },
  {
    title: "สถานะ",
    dataIndex: "claimStatus",
    align: "center",
    className: "td-content td-content-confirm",
    width: "8vw",
    render: (claimStatus) => <ColumnStatus status={claimStatus} />,
    onCell: (record, i) => ({
      onClick: () => fn(record),
    }),
    filters: [
      {
        text: "จัดส่งเรียบร้อย",
        value: 1,
      },
      {
        text: "รอตรวจสอบ",
        value: 2,
      },
      {
        text: "ดำเนินการ",
        value: 3,
      },
      {
        text: "ยกเลิกเคลม",
        value: 4,
      },
      {
        text: "รอชำระเงิน",
        value: 5,
      },
      {
        text: "ชำระเงินเรียบร้อย",
        value: 6,
      },
    ],
    onFilter: (value, record) => +record.claimStatus === +value,
  },
  {
    title: "เลขพัสดุ",
    dataIndex: "confirm_ems",
    align: "center",
    className: "td-content",
    width: "7vw",
    render: (text, record) =>
      text === "กรอกเลขพัสดุ" ? (
        <BtnConfirm title={text} onClick={() => onClick(record, 0)} />
      ) : text !== undefined && text !== "" && text !== null ? (
        <font onClick={() => onClick(record, 0)}>{text}</font>
      ) : (
        text
      ),
    onCell: (record, i) => ({
      onClick:
        record.shipping && record.shipping.ems
          ? () => onClick(record, 1)
          : null,
    }),
  },
  {
    title: "วันที่จัดส่ง",
    dataIndex: "shipping_date",
    align: "center",
    className: "td-content",
    width: "7.8vw",
    onCell: (record, i) => ({
      onClick: () => fn(record),
    }),
    sorter: (a, b) =>
      a.shipping_date < b.shipping_date
        ? -1
        : a.shipping_date > b.shipping_date
          ? 1
          : 0,
  },
  // {
  //   title: "หมายเหตุ",
  //   dataIndex: "note",
  //   align: "center",
  //   className: "td-content",
  //   width: "7vw",
  //   onCell: (record, i) => ({
  //     onClick: () => fn(record),
  //   }),
  // },
  {
    title: "Edit Product",
    dataIndex: "edit_product",
    align: "center",
    className: "td-content",
    width: "7vw",
    // onCell: (record, i) => ({
    //     onClick: () => fn(record)
    // }),
  },
  {
    title: "ส่งข้อความ",
    dataIndex: "sendMessage",
    align: "center",
    className: "td-content",
    fixed: "right",
    width: "4vw",
    render: (text, record) => (
      <SendOutlined
        style={{ color: "green" }}
        onClick={() => onCreateMessage(record)}
      />
    ),
    onCell: (record, i) => ({
      onClick: () => onCreateMessage(record),
    }),
  },
];
export const columnTableClaimFocus = (
  fn = () => { },
  onClick = () => { },
  onUactivate = () => { }
) => [
    {
      title: "ลำดับ",
      dataIndex: "no",
      align: "center",
      width: "3vw",
      className: "td-content",
      onCell: (record, i) => ({
        onClick: () => fn(record),
      }),
      sorter: (a, b) => a.no - b.no,
    },
    {
      title: "เลขที่เคลม",
      dataIndex: "no_claim",
      align: "center",
      className: "td-content",
      width: "10vh",
      onCell: (record, i) => ({
        onClick: () => fn(record),
      }),
      sorter: (a, b) =>
        a.no_claim < b.no_claim ? -1 : a.no_claim > b.no_claim ? 1 : 0,
    },

    {
      title: "ชื่อ-นามสกุล",
      dataIndex: "name",
      align: "center",
      className: "td-content",
      width: "9vw",
      onCell: (record, i) => ({
        onClick: () => fn(record),
      }),
    },
    {
      title: "เบอร์โทรศัพท์",
      dataIndex: "tel",
      align: "center",
      className: "td-content",
      width: "9vw",
      onCell: (record, i) => ({
        onClick: () => fn(record),
      }),
    },
    {
      title: "วันที่แจ้ง",
      dataIndex: "dateclaim_formate",
      align: "center",
      width: "5vw",
      className: "td-content",
      onCell: (record, i) => ({
        onClick: () => fn(record),
      }),
      sorter: (a, b) =>
        a.dateclaim < b.dateclaim ? -1 : a.dateclaim > b.dateclaim ? 1 : 0,
    },
    {
      title: "รุ่นสินค้า",
      dataIndex: "nameproduct",
      align: "center",
      className: "td-content",
      width: "8vw",
      onCell: (record, i) => ({
        onClick: () => fn(record),
        style: {
          whiteSpace: "wrap",
          maxWidth: "8vw",
        },
      }),
      render: (text) => {
        return (
          <div style={{ fontSizeAdjust: 1 }}>
            <font className={"font-table"}>{text}</font>
          </div>
        );
      },
    },
    {
      title: "เวลารับประกันคงเหลือ (วัน)",
      dataIndex: "time_warranty",
      align: "center",
      className: "td-content",
      width: "8vw",
      onCell: (record, i) => ({
        onClick: () => fn(record),
      }),
      sorter: (a, b) =>
        a.time_warranty < b.time_warranty
          ? -1
          : a.time_warranty > b.time_warranty
            ? 1
            : 0,
    },
    {
      title: "สถานะ",
      dataIndex: "status",
      align: "center",
      className: "td-content td-content-confirm",
      width: "8vw",
      render: (status, record) => (
        <ColumnStatus_FOCUS status={status} type={record.type} />
      ),
      onCell: (record, i) => ({
        onClick: () => fn(record),
      }),
      filters: [
        {
          text: "รอชำระเงิน",
          value: 1,
        },
        {
          text: "รอการตรวจสอบ",
          value: 2,
        },
        {
          text: "ชำระเงินสำเร็จ",
          value: 3,
        },
        {
          text: "จัดส่งเรียบร้อย",
          value: 4,
        },
        {
          text: "ไม่อนุมัติ",
          value: 99,
        },
      ],
      onFilter: (value, record) => +record.status === +value,
    },
    {
      title: "ประเภทการเคลม",
      dataIndex: "type",
      align: "center",
      className: "td-content td-content-confirm",
      width: "8vw",
      render: (type) => <ColumnStore_type type={type} />,
      onCell: (record, i) => ({
        onClick: () => fn(record),
      }),
      filters: [
        {
          text: "DPLUS",
          value: "COMPANY",
        },
        {
          text: "STORE",
          value: "STORE",
        },
      ],
      onFilter: (value, record) => record.type === value,
    },
    {
      title: "Confirm EMS",
      dataIndex: "confirm_ems",
      align: "center",
      className: "td-content",
      width: "7vw",
      render: (text, record) =>
        record.type === "STORE" ? (
          ""
        ) : text === "กรอก EMS" ? (
          <BtnConfirm title={text} onClick={() => onClick(record, 0)} />
        ) : (
          text
        ),

      onCell: (record, i) => ({
        onClick: record.tracking ? () => onClick(record, 1) : null,
      }),
    },
    {
      title: "วันที่ส่งสินค้า",
      dataIndex: "shipping_formate",
      align: "center",
      className: "td-content",
      width: "7.8vw",
      onCell: (record, i) => ({
        onClick: () => fn(record),
      }),
      sorter: (a, b) =>
        a.shipping_date < b.shipping_date
          ? -1
          : a.shipping_date > b.shipping_date
            ? 1
            : 0,
    },
    {
      title: "ปลดรหัสประกัน",
      dataIndex: "unactive",
      align: "center",
      className: "td-content",
      width: "7vw",
      render: (text, record) =>
        text === "รหัสประกัน" ? (
          <BtnConfirm title={text} onClick={() => onUactivate(record, 0)} />
        ) : (
          text
        ),
      onCell: (record, i) => ({
        onClick: record.unactive_code ? () => onUactivate(record, 1) : null,
      }),
    },
    {
      title: "เคลม Dealer",
      dataIndex: "document_id",
      align: "center",
      className: "td-content",
      width: "7vw",
      onCell: (record, i) => ({
        onClick: () => fn(record),
      }),
    },

    // {
    //     title: 'หมายเหตุ',
    //     dataIndex: 'note',
    //     align: 'center',
    //     className: 'td-content',
    //     onCell: (record, i) => ({
    //         onClick: () => fn(record)
    //     }),
    //     render: note => <font style={{ fontSize: '0.6vw' }}>{note}</font>
    // },
  ];

export const columnTableAccounting = [
  {
    title: "ลำดับ",
    dataIndex: "no",
    align: "center",
    width: "3vw",
    className: "td-content",
    sorter: (a, b) => a.no - b.no,
  },
  {
    title: "วันที่โอน",
    dataIndex: "date_pay",
    align: "center",
    width: "5vw",
    className: "td-content",
    sorter: (a, b) =>
      a.dateclaim < b.dateclaim ? -1 : a.dateclaim > b.dateclaim ? 1 : 0,
  },
  {
    title: "เลขที่เคลม",
    dataIndex: "no_claim",
    align: "center",
    className: "td-content",
    width: "10vh",
  },

  {
    title: "ชื่อ-นามสกุล",
    dataIndex: "name",
    align: "center",
    className: "td-content",
    width: "9vw",
  },
  {
    title: "เบอร์โทรศัพท์",
    dataIndex: "tel",
    align: "center",
    className: "td-content",
    width: "9vw",
  },
  {
    title: "จำนวนเงิน",
    dataIndex: "money_pay",
    align: "center",
    className: "td-content",
    width: "9vw",
  },
  {
    title: "รหัสสินค้า",
    dataIndex: "item_code",
    align: "center",
    className: "td-content",
    width: "9vw",
  },
];
export const columnTableImportAx = [
  {
    title: "ลำดับ",
    dataIndex: "no",
    align: "center",
    width: "3vw",
    className: "td-content",
    sorter: (a, b) => a.no - b.no,
  },
  {
    title: "วันที่แจ้งเคลม",
    dataIndex: "dateclaim_formate",
    align: "center",
    width: "5vw",
    className: "td-content",
    sorter: (a, b) =>
      a.dateclaim < b.dateclaim ? -1 : a.dateclaim > b.dateclaim ? 1 : 0,
  },
  {
    title: "วันที่ส่งสินค้า",
    dataIndex: "shipping_formate",
    align: "center",
    width: "5vw",
    className: "td-content",
    sorter: (a, b) =>
      a.dateclaim < b.dateclaim ? -1 : a.dateclaim > b.dateclaim ? 1 : 0,
  },
  {
    title: "เลขที่เคลม",
    dataIndex: "no_claim",
    align: "center",
    className: "td-content",
    width: "10vh",
  },
  {
    title: "ชื่อสินค้า",
    dataIndex: "nameproduct",
    align: "center",
    className: "td-content",
    width: "9vw",
  },
  {
    title: "ยี่ห้อมือถือ",
    dataIndex: "brandmobile",
    align: "center",
    className: "td-content",
    width: "9vw",
  },
  {
    title: "รุ่นมือถือ",
    dataIndex: "modelmobile",
    align: "center",
    className: "td-content",
    width: "9vw",
  },
  {
    title: "รหัสสินค้า",
    dataIndex: "item_code",
    align: "center",
    className: "td-content",
    width: "9vw",
  },
  {
    title: "เลขเอกสารจาก AX",
    dataIndex: "import_doc",
    align: "center",
    className: "td-content",
    width: "9vw",
  },
  {
    title: "สถานะการ Import",
    dataIndex: "import_status",
    align: "center",
    className: "td-content td-content-confirm",
    width: "8vw",
    render: (import_status) => (
      <ColumnStatus_ImportAx_Focus status={import_status} />
    ),

    filters: [
      {
        text: "รอการดำเนินการ",
        value: 99,
      },
      {
        text: "Importสำเร็จ",
        value: 1,
      },
      {
        text: "Importไม่สำเร็จ",
        value: 2,
      },
    ],
    onFilter: (value, record) => +record.import_status === +value,
  },
];

export const columnTableReport = [
  {
    title: "ลำดับ",
    dataIndex: "no",
    align: "center",
  },
  {
    title: "รายการสินค้า",
    dataIndex: "product_name",
    align: "center",
    width: "30%",
  },
  {
    title: "รูปสินค้า",
    dataIndex: "product_image",
    align: "center",
    render: (url) => (
      <img src={url} style={{ width: "2cm", height: "3cm" }} alt={""} />
    ),
  },
  {
    title: "จำนวน",
    dataIndex: "count",
    align: "center",
  },
];
export const columnTableReportFocus = [
  {
    title: "ลำดับ",
    dataIndex: "no",
    align: "center",
  },
  {
    title: "รายการสินค้า",
    dataIndex: "product_name",
    align: "center",
    width: "30%",
  },
  {
    title: "รูปสินค้า",
    dataIndex: "product_image",
    align: "center",
    render: (url) => (
      <img src={url} style={{ width: "2cm", height: "3cm" }} alt={""} />
    ),
  },
  {
    title: "จำนวน",
    dataIndex: "count",
    align: "center",
  },
];

export const columnTableHistoryClaim = [
  {
    title: "ลำดับ",
    dataIndex: "no",
    align: "center",
  },
  {
    title: "ชื่อไลน์",
    dataIndex: "line_name",
    align: "center",
    // width: '30%'
  },
  {
    title: "ชื่อ-นามสกุล",
    dataIndex: "name",
    align: "center",
  },
  {
    title: "เบอร์โทร",
    dataIndex: "telephone",
    align: "center",
  },
  // {
  //     title: 'รหัสสินค้าลงทะเบียน',
  //     dataIndex: 'product_register',
  //     align: 'center',
  //     sorter: (a, b) => a.product_register < b.product_register ? -1 : a.product_register > b.product_register ? 1 : 0
  // },
  // {
  //     title: 'ชื่อสินค้า',
  //     dataIndex: 'product_name',
  //     align: 'center',
  // },
  {
    title: "จำนวนการเคลม",
    dataIndex: "count",
    align: "center",
    sorter: (a, b) => (a.count < b.count ? -1 : a.count > b.count ? 1 : 0),
  },
  {
    title: "วันที่เคลมล่าสุด",
    dataIndex: "last_date",
    align: "center",
    sorter: (a, b) =>
      a.last_date < b.last_date ? -1 : a.last_date > b.last_date ? 1 : 0,
  },
];

export const FocuscolumnTableHistory = [
  {
    title: "ลำดับ",
    dataIndex: "no",
    align: "center",
  },

  {
    title: "ชื่อ-นามสกุล",
    dataIndex: "name",
    align: "center",
  },
  {
    title: "เบอร์โทร",
    dataIndex: "telephone",
    align: "center",
  },

  {
    title: "จำนวนการเคลม",
    dataIndex: "count",
    align: "center",
    sorter: (a, b) => (a.count < b.count ? -1 : a.count > b.count ? 1 : 0),
  },
  {
    title: "วันที่เคลมล่าสุด",
    dataIndex: "last_date",
    align: "center",
    sorter: (a, b) =>
      a.last_date < b.last_date ? -1 : a.last_date > b.last_date ? 1 : 0,
  },
];
export const columnTableReportWarranty = (onClickRow = () => { }, appoveWarranty =()=>{ }) => [
  {
    title: "ลำดับ",
    dataIndex: "no",
    align: "center",
    onCell: (record, i) => ({
      onClick: () => onClickRow(record),
    }),
  },
  {
    title: "เลขที่ประกัน",
    dataIndex: "warrantyNo",
    align: "center",
    onCell: (record, i) => ({
      onClick: () => onClickRow(record),
    }),
  },
  {
    title: "ชื่อ-นามสกุล",
    dataIndex: "customerName",
    align: "center",
    onCell: (record, i) => ({
      onClick: () => onClickRow(record),
    }),
  },
  {
    title: "ชื่อสินค้า",
    dataIndex: "productName",
    align: "center",
    onCell: (record, i) => ({
      onClick: () => onClickRow(record),
    }),
  },
  {
    title: "แบรนด์",
    dataIndex: "brandMobileName",
    align: "center",
    onCell: (record, i) => ({
      onClick: () => onClickRow(record),
    }),
  },
  {
    title: "รุ่น",
    dataIndex: "modelMobileName",
    align: "center",
    onCell: (record, i) => ({
      onClick: () => onClickRow(record),
    }),
  },
  {
    title: "สถานะ",
    dataIndex: "reportWarrantyStatus",
    align: "center",
    className: "td-content td-content-confirm",
    width: "8vw",
    render: (reportWarrantyStatus) => (
      <ColumnStatusReportWarranty status={reportWarrantyStatus} />
    ),
    onCell: (record, i) => ({
      onClick: () => onClickRow(record),
    }),
    filters: [
      {
        text: "ลงทะเบียน",
        value: 0,
      },
      {
        text: "จัดส่งเรียบร้อย",
        value: 1,
      },
      {
        text: "รอตรวจสอบ",
        value: 2,
      },
      {
        text: "ดำเนินการ",
        value: 3,
      },
      {
        text: "ยกเลิกเคลม",
        value: 4,
      },
      {
        text: "-",
        value: 5,
      },
    ],
    onFilter: (value, record) => +record.reportWarrantyStatus === +value,
  },

  {
    title: "เบอร์มือถือ",
    dataIndex: "customerTelephone",
    align: "center",
    onCell: (record, i) => ({
      onClick: () => onClickRow(record),
    }),
  },
  {
    title: "วันที่ลงทะเบียน",
    dataIndex: "warrantyDate",
    align: "center",
    onCell: (record, i) => ({
      onClick: () => onClickRow(record),
    }),
    sorter: (a, b) =>
      a.warrantyDate < b.warrantyDate
        ? -1
        : a.warrantyDate > b.warrantyDate
          ? 1
          : 0,
  },
  {
    title: "ช่องทางการซื้อ",
    dataIndex: "channelName",
    align: "center",
    onCell: (record, i) => ({
      onClick: () => onClickRow(record),
    }),
  },
  {
    title: "วันที่สิ้นสุดรับประกัน",
    dataIndex: "endDate",
    align: "center",
    onCell: (record, i) => ({
      onClick: () => onClickRow(record),
    })
  },
  {
    title: "ตัดรับประกัน",
    dataIndex: "",
    align: "center",
    render:(text,record)=>{
      return <ScissorOutlined onClick={()=>appoveWarranty(record)}/>
    },
   
  },
];

export const FocuscolumnTableWarranty = [
  {
    title: "ลำดับ",
    dataIndex: "no",
    align: "center",
  },

  {
    title: "เลขที่ประกัน",
    dataIndex: "no_warranty",
    align: "center",
  },
  {
    title: "ชื่อ-นามสกุล",
    dataIndex: "name",
    align: "center",
  },
  {
    title: "ชื่อสินค้า",
    dataIndex: "nameproduct",
    align: "center",
  },
  {
    title: "แบรน",
    dataIndex: "brandmobile",
    align: "center",
  },
  {
    title: "รุ่น",
    dataIndex: "modelmobile",
    align: "center",
  },
  {
    title: "สถานะ",
    dataIndex: "status",
    align: "center",
    className: "td-content td-content-confirm",
    width: "8vw",
    render: (status, record) => (
      <ColumnStatus_FOCUS status={status} type={"COMPANY"} />
    ),

    filters: [
      {
        text: "ลงทะเบียน",
        value: 0,
      },
      {
        text: "รอชำระเงิน",
        value: 1,
      },
      {
        text: "รอการตรวจสอบ",
        value: 2,
      },
      {
        text: "ชำระเงินสำเร็จ",
        value: 3,
      },
      {
        text: "จัดส่งเรียบร้อย",
        value: 4,
      },
      {
        text: "ไม่อนุมัติ",
        value: 99,
      },
    ],
    onFilter: (value, record) => +record.status === +value,
  },

  {
    title: "เบอร์โทร",
    dataIndex: "telephone",
    align: "center",
  },
  {
    title: "วันที่ลงทะเบียน",
    dataIndex: "create",
    align: "center",
    sorter: (a, b) => (a.create < b.create ? -1 : a.create > b.create ? 1 : 0),
  },
];

export const KEY_BILL = "KqkTxGXzw1";

export const STATUS_CLAIM = [
  {
    status_id: 1,
    status_name: "จัดส่งเรียบร้อย",
  },
  {
    status_id: 2,
    status_name: "รอตรวจสอบ",
  },
  {
    status_id: 3,
    status_name: "ดำเนินการ",
  },
  {
    status_id: 4,
    status_name: "ยกเลิกเคลม",
  },
  {
    status_id: 5,
    status_name: "รอชำระเงิน",
  },
  {
    status_id: 6,
    status_name: "ชำระเงินเรียบร้อย",
  },

  // {
  //     status_id: 0,
  //     status_name: 'รอรับเรื่อง'
  // }, {
  //     status_id: 1,
  //     status_name: 'กำลังดำเนินการ'
  // }, {
  //     status_id: 2,
  //     status_name: 'จัดส่งเรียบร้อย'
  // }, {
  //     status_id: 99,
  //     status_name: 'ไม่อนุมัติ'
  // }
];
export const STATUS_CLAIM_FOCUS = [
  {
    status_id: 0,
    status_name: "ลงทะเบียน",
  },
  {
    status_id: 1,
    status_name: "รอชำระเงิน",
  },
  {
    status_id: 2,
    status_name: "รอการตรวจสอบ",
  },
  {
    status_id: 3,
    status_name: "ชำระเงินเรียบร้อย",
  },
  {
    status_id: 4,
    status_name: "จัดส่งเรียบร้อย",
  },
  {
    status_id: 99,
    status_name: "ไม่อนุมัติ",
  },
];
export const STATUS_CLAIM_DEALER = [
  {
    status_id: 2,
    status_name: "รอรับบริการ",
  },
  {
    status_id: 3,
    status_name: "เคลมเสร็จเรียบร้อย",
  },
  {},
];
export const STORE_FOCUS = [
  {
    original_name: "COMPANY",
    thai_name: "DPLUS",
  },
  {
    original_name: "STORE",
    thai_name: "STORE",
  },
  {
    original_name: "",
    thai_name: "",
  },
];
export const STATUS_REPORT_REGISTER = [
  {
    status_id: 0,
    status_name: "ลงทะเบียน",
  },
  {
    status_id: 1,
    status_name: "จัดส่งเรียบร้อย",
  },
  {
    status_id: 2,
    status_name: "รอตรวจสอบ",
  },
  {
    status_id: 3,
    status_name: "ดำเนินการ",
  },
  {
    status_id: 4,
    status_name: "ยกเลิกเคลม",
  },
  {
    status_id: 5,
    status_name: "-",
  },
];
export const STATUS_IMPORTAX_FOCUS = [
  {
    status_id: 99,
    status_name: "รอการดำเนินการ",
  },
  {
    status_id: 1,
    status_name: "Importเข้าเรียบร้อย",
  },
  {
    status_id: 2,
    status_name: "Importไม่สำเร็จ",
  },
];

export const STATUS_STORE_CLAIM = [
  {
    status_id: 0,
    status_name: "COMPANY",
  },
  {
    status_id: 1,
    status_name: "STORE",
  },
  {
    status_id: 2,
    status_name: "",
  },
];

export const FORM_EDIT_PRODUCT = [
  {
    label: "Barcode",
    type: "SEARCH",
  },
  {
    label: "ชื่อสินค้า",
    type: "TEXT_NAME",
  },
  {
    label: "ประเภทสินค้า",
    type: "TEXT_TYPE",
  },
  {
    label: "อาการเสีย",
    type: "DROPDOWN_BREAK",
  },
  {
    label: "ภาพสินค้า",
    type: "IMAGE",
  },
];

////////////////////////Rizzzz//////////////////////////
export const FORM_ADD_PRODUCT_WARRANTY = [
  {
    label: "บาร์โค้ดสินค้า",
    type: "text",
    name: "barcode",
    value: "",
    required: true,
  },

  {
    label: "ชื่อสินค้า",
    name: "productName",
    type: "text",
    value: "",
    required: true,
  },
  {
    label: "รหัสสินค้า",
    name: "productCode",
    type: "text",
    value: "",
    required: true,
  },
  {
    label: "ประเภทสินค้า",
    type: "select",
    name: "productTypeID",
    value: 1,
    required: true,
  },

  {
    label: "สีสินค้า",
    type: "text",
    name: "colors",
    value: "",
    required: false,
  },
  {
    label: "ProductList",
    type: "text",
    name: "productList",
    value: "",
    required: false,
  },
  {
    label: "สถานะ (ลงทะเบียน)",
    type: "switch",
    name: "productWarrantyStatus",
    value: true,
    required: false,
  },
  {
    label: "สถานะ (เคลม)",
    type: "switch",
    name: "productClaimStatus",
    value: true,
    required: false,
  },
  {
    label: "อัพโหลดรูปสินค้า",
    type: "upload",
    name: "productImage",
    value: [],
    required: false,
  },
];

export const FORM_EDIT_PRODUCT_WARRANTY = [
  {
    label: "บาร์โค้ดสินค้า",
    type: "text",
    name: "barcode",
    value: "",
    required: true,
  },
  {
    label: "",
    name: "productID",
    type: "hidden",
    value: "",
    required: false,
  },

  {
    label: "ชื่อสินค้า",
    name: "productName",
    type: "text",
    value: "",
    required: true,
  },
  {
    label: "รหัสสินค้า",
    name: "productCode",
    type: "text",
    value: "",
    required: true,
  },
  {
    label: "ประเภทสินค้า",
    type: "select",
    name: "productTypeID",
    value: "",
    required: true,
  },

  {
    label: "สีสินค้า",
    type: "text",
    name: "colors",
    value: "",
    required: false,
  },
  {
    label: "ProductList",
    type: "text",
    name: "productList",
    value: "",
    required: false,
  },
  {
    label: "สถานะ (ลงทะเบียน)",
    type: "switch",
    name: "productWarrantyStatus",
    value: true,
    required: false,
  },
  {
    label: "สถานะ (เคลม)",
    type: "switch",
    name: "productClaimStatus",
    value: true,
    required: false,
  },
  {
    label: "อัพโหลดรูปสินค้า",
    type: "upload",
    name: "productImage",
    value: [],
    required: false,
  },
];
export const columnTableProductsWarranty = [
  {
    title: "ลำดับ",
    dataIndex: "no",
    align: "center",
    width: "8%",
    className: "td-content",
    sorter: (a, b) => a.no - b.no,
  },
  {
    title: "รูปสินค้า",
    dataIndex: "productImage",
    align: "center",
    width: "12%",
    className: "td-content",
    render:(text)=>{
      return <img src={text?text:image.noImage} width={'100px'}/>
    }
  },
  {
    title: "ชื่อสินค้า",
    dataIndex: "productName",
    align: "center",
    width: "25%",
    className: "td-content",
  },
  {
    title: "ประเภทสินค้า",
    dataIndex: "productTypeNameTH",
    align: "center",
    width: "10%",
    className: "td-content",
  },
  {
    title: "บาร์โค้ด",
    dataIndex: "barcode",
    align: "center",
    width: "15%",
    className: "td-content",
  },
  {
    title: "สถานะ (ลงทะเบียน)",
    dataIndex: "productWarrantyStatus",
    align: "center",
    width: "10%",
    className: "td-content",
    render: (text, record) => {
      return <Tag color={text == 1 ? "#87d068" : "#f50"}>{record.productWarrantyStatusName}</Tag>
    }
  },
  {
    title: "สถานะ (เคลม)",
    dataIndex: "productClaimStatus",
    align: "center",
    width: "10%",
    className: "td-content",
    render: (text, record) => {
      return <Tag color={text == 1 ? "#87d068" : "#f50"}>{record.productClaimStatusName}</Tag>
    }
  },
  {
    title: "แก้ไข",
    dataIndex: "productEdit",
    align: "center",
    width: "5%",
    className: "td-content",
    // render: (text, record) => (
    //   // <Button>
    //   <EditOutlined
    //     style={{ fontSize: "22px", color: "#faad14" }}
    //     onClick={this.setState({ openEdit: true })}
    //   />
    //   // แก้ไข
    //   // </Button>
    // ),
  },
];
